// /* eslint-disable */
import React, { Component} from 'react';
import {
    Button, Card,
    Col, DatePicker, Divider, Form,
    Input,
    InputNumber,
    message,
    Modal, Popconfirm,
    Row,
    Select, Table, Tooltip
} from 'antd';
import axios from 'axios';
import EditReturn  from "./EditReturn";
import AddReturn from "./AddReturn";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    PrinterOutlined,
    ContainerOutlined,
    PlusOutlined
} from "@ant-design/icons";
import Cookies from "js-cookie";
import {Redirect} from "react-router-dom";



const { Option } = Select;
const { RangePicker } = DatePicker;


class Return extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: false,
            filteredTableData: [],
            isUpdateModalVisible: false,
            isViewModalVisible: false,
            isPaymentModalVisible: false,
            selectedItem: null,
            tableData: [],
            customerOptions: [],
            searchCode: '',
            searchStatus: '',
            searchStartDate: null,
            searchEndDate: null,
        };

        // Bind methods
        this.handleViewShow = this.handleViewShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllTransactionsCashBook = this.getAllTransactionsCashBook.bind(this);
        this.toggleViewModal = this.toggleViewModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.togglePaymentModal = this.togglePaymentModal.bind(this);


    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getAllCustomers");
            //console.log("response", response);

            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    async componentDidMount() {
        const customerOptions = await this.fetchCustomerOptions();
        this.setState({customerOptions});

        this.getAllTransactionsCashBook();
        // this.fetchData();
    }

    handleClear = async () => {
        this.getAllTransactionsCashBook();
        this.formRef.current.resetFields();

    };

    handleSearch = async () => {
        this.setState({ loading: true });

        await this.getAllTransactionsCashBook();

        try {
            // Prepare the search criteria
            const searchData = {
                code: this.state.searchCode,
                status: this.state.searchStatus,
                startDate: this.state.searchStartDate,
                endDate: this.state.searchEndDate,
            };

            this.setState({
                searchCode: null,
                searchStatus: null,
                searchStartDate: null,
                searchEndDate: null,
            });

            //Search Using FrontEnd tableData array
            let filteredTableData = this.state.tableData;

            filteredTableData = filteredTableData.filter((item) => {
                let isValid = true;

                if (searchData.code) {
                    isValid = isValid && item.CODE.toLowerCase().includes(searchData.code.toLowerCase());
                }

                if (searchData.status) {
                    isValid = isValid && item.TYPE === searchData.status;
                }

                if (searchData.startDate) {
                    isValid = isValid && new Date(item.DATE) >= new Date(searchData.startDate);
                }

                if (searchData.endDate) {
                    isValid = isValid && new Date(item.DATE) <= new Date(searchData.endDate);
                }

                return isValid;
            }
            );
            this.state.tableData = filteredTableData;

        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    };



    handleViewShow(row) {
        console.log('row', row);
        if(row.TYPE === 'Payment'){
            this.setState({
                selectedItem: row,
                isPaymentModalVisible: true,
            });
        }
        else {
            this.setState({
                selectedItem: row,
                isViewModalVisible: true,
            });
        }

    }


    handleDelete = async (id) => {
        //console.log('id', id);
        //console.log('all', all);
        try {
            // Make an API call to deactivate the customer
            const response = await axios.post('https://iontheway.store/api/deactivateReturn', {
                RETURN_ID: id
            });

            if (response.data.success) {
                message.success('Return deleted successfully');
                // Refresh the table
                await this.getAllTransactionsCashBook();
            } else {
                message.error('Failed to delete customer');
            }
        } catch (error) {
            console.error('Error deleting customer:', error);
            message.error('Internal server error');
        }
    };






    async getAllTransactionsCashBook() {
        this.setState({ loading: true });
        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }


        try {
            let response;
            if(ROLE === 'ADMIN') {
                response = await axios.post('https://iontheway.store/api/getAllReturns');
            }
            else {
                response = await axios.post('https://iontheway.store/api/getAllReturnsByUser');
            }

            if (response.data.success) {
                const items = response.data.result;
                console.log('items', items);

                this.state.tableData = items;
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    toggleViewModal() {
        this.setState({
            isViewModalVisible: !this.state.isViewModalVisible,
            selectedItem: null,
        });
    }

    togglePaymentModal() {
        this.setState({
            isPaymentModalVisible: !this.state.isPaymentModalVisible,
            selectedItem: null,
        });
    }

    filterDue = async () => {
        this.setState({ loading: true });
        await this.getAllTransactionsCashBook();
        let filteredDueItems = [];

        this.state.tableData.map((item) => {
            if(item.DUE === true && item.TYPE !== 'Expenses'){
                filteredDueItems.push(item);
            }
        }
        );
        this.state.tableData = filteredDueItems;
        this.setState({ loading: false });

    }


    render() {

        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        const buttonStylePrint = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            backgroundColor: '#52c41a',
            display: 'flex',
            color: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
        };



        const paginationConfig = {
            pageSize: 50,
            total: this.state.tableData.length,
            showSizeChanger: false,
        };
        return (
            <>
                {/* Search Filter */}
                <div>
                    <Form
                        ref={this.formRef}
                        initialValues={{
                            searchCode: null,
                            searchStatus: null,
                            searchDateRange: null,
                        }}
                        onFinish={this.handleSearch}
                    >
                        <Row gutter={[16, 16]} justify="left" align="top">
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchCode">
                                    <Input
                                        placeholder="Search by Return Code"
                                        onChange={(e) => this.setState({ searchCode: e.target.value })}
                                        style={{ width: '100%'}}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchCustomer">
                                    <Select
                                        placeholder="Select Return Type"
                                        allowClear
                                        showSearch
                                        onChange={(value) => this.setState({ searchStatus: value })}
                                        filterOption={(input, option) =>
                                            (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                            (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                        }
                                    >
                                        <Option key="Returns" value="Returns" title="Returns">
                                            Returns
                                        </Option>
                                        <Option key="Losses" value="Losses" title="Losses">
                                            Losses
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchDateRange">
                                    <RangePicker
                                        style={{ width: '100%' }}
                                        onChange={(dates) => {
                                            this.setState({
                                                searchStartDate: dates && dates.length === 2 ? dates[0].format('YYYY-MM-DD') : null,
                                                searchEndDate: dates && dates.length === 2 ? dates[1].format('YYYY-MM-DD') : null,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item>
                                    <Button type="default" htmlType="submit" style={{ marginRight: '8px' }}>
                                        Filter
                                    </Button>
                                    <Button type="default" danger onClick={this.handleClear} style={{ marginRight: '8px' }}>
                                        Clear
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>

                </div>

                {/* Cards and Tables */}
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title='Returns and Losses'
                                extra={
                                    <>
                                        <Button className="primary" onClick={this.togglePaymentModal} style={{ margin: '10px' }}>
                                            <PlusOutlined /> Add Return/Loss
                                        </Button>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        rowKey="RETURN_ID"
                                        style={{ margin: '15px' }}
                                        columns={[
                                            {
                                                title: 'Return/Loss ID',
                                                dataIndex: 'CODE'
                                            },
                                            {
                                                title: 'Date',
                                                dataIndex: 'DATE',
                                                render: (row) => (
                                                    <span> {new Date(row).toLocaleDateString()}</span>
                                                ),
                                            },

                                            {
                                                title: 'Amount',
                                                dataIndex: 'SUB_TOTAL',
                                                render: (text, record) => {
                                                    if (record.TYPE === 'Losses') {
                                                        return <div style={{ color: 'red' }}>- Rs. {record.SUB_TOTAL}</div>;
                                                    } else {
                                                        return <div style={{ color: 'green' }}>+ Rs. {record.SUB_TOTAL}</div>;
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Edit">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size="large"
                        style={buttonStyle}
                        onClick={() => this.handleViewShow(row)}
                    />
                </Tooltip>
                <Divider
                    type="vertical"
                    style={{ height: '50px', display: 'flex', alignItems: 'center' }}
                />
                <Tooltip title="Delete">
                    <Popconfirm
                        title={`Are you sure you want to delete this?`}
                        onConfirm={() => this.handleDelete(row.RETURN_ID)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            danger
                            type="primary"
                            icon={<DeleteOutlined />}
                            size="large"
                            style={buttonStyle}
                        />
                    </Popconfirm>
                </Tooltip>
            </span>

                                                ),
                                            },

                                        ]}
                                        dataSource={this.state.tableData}
                                        pagination={paginationConfig}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>

                 {/*View Return Modal*/}
                <Modal
                    title="Update Return/Loss"
                    visible={this.state.isViewModalVisible}
                    onCancel={this.toggleViewModal}
                    footer={null}
                    width={1250}
                >
                    {this.state.selectedItem && (
                        <EditReturn
                            key={this.state.selectedItem.RETURN_ID} // Pass a key to ensure a new instance is created
                            initialValues={this.state.selectedItem}
                            onCancel={this.toggleViewModal}
                            onUpdate={this.getAllTransactionsCashBook}
                        />
                    )}
                </Modal>

                {/*Payment Modal*/}
                <Modal
                    title="Add Return/Loss"
                    visible={this.state.isPaymentModalVisible}
                    onCancel={this.togglePaymentModal}
                    footer={null}
                    width={1250}
                >
                        <AddReturn
                            onCancel={this.togglePaymentModal}
                            onUpdate={this.getAllTransactionsCashBook}
                        />
                </Modal>




            </>
        );
    }
}

export default Return;
