/* eslint-disable */

import React, { Component } from 'react';
import {
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Select,
    Switch,
    Upload,
    Button,
    message,
    DatePicker,
    Input, Divider, Modal
} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import moment from 'moment';
import {RightOutlined, UploadOutlined} from "@ant-design/icons";

const { Option } = Select;

class UpdateEditStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.formRef = React.createRef();
    }


    handleSubmit = async (values) => {
        try {
            // Retrieve USER_ID from rememberedUser
            let rememberedUser = Cookies.get('rememberedUser');
            let USER_ID = null;

            if (rememberedUser) {
                rememberedUser = JSON.parse(rememberedUser);
                USER_ID = rememberedUser.USER_ID;
            }

            const checkName = {
                CODE: values.CODE,
                ITEM_ID : this.props.initialValues.ITEM_ID
            };

            const checkDuplicateName = await axios.post('https://iontheway.store/api/checkForDuplicateNameUpdate', checkName);

            if (checkDuplicateName.data.duplicate) {
                message.error('Item code already exists');
                return;
            }

            // Main data for the request
            const mainData = {
                ...values,
                ITEM_ID: this.props.initialValues.ITEM_ID,
            };

            // Update the Item
            const updateItem = await axios.post('https://iontheway.store/api/updateItem', mainData);

            if (updateItem.data.success) {
                message.success('Item updated successfully');
                this.props.onUpdate();
                this.props.onCancel();
            }
            else {
                message.error('Internal server error');
            }

        } catch (error) {
            console.error('Error adding Heat Treatment:', error);
            message.error('Internal server error');
        }
    };

    handleInputChange = (e) => {
        e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
    }

    render() {
        const inputStyle = {
            width: '100%',
        };


        return (

            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                className="criclebox tablespace mb-24"
                            >
                                <Form
                                    layout="vertical"
                                    onFinish={this.handleSubmit}
                                    style={{ margin: '20px' }}
                                    ref={this.formRef}
                                >
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                label="Name"
                                                name="NAME"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the name',
                                                    },
                                                ]}
                                                initialValue={this.props.initialValues.NAME}
                                            >
                                                <Input style={inputStyle} placeholder="Enter the name" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                label="Code"
                                                name="CODE"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the item code',
                                                    },
                                                ]}
                                                initialValue={this.props.initialValues.CODE}
                                            >
                                                <Input style={inputStyle} placeholder="Enter the code" onInput={this.handleInputChange} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                label="Buying Price (Rs.)"
                                                name="BUYING_PRICE"
                                                initialValue={this.props.initialValues.BUYING_PRICE}
                                            >
                                                <InputNumber style={inputStyle} step={0.01} placeholder="Enter the price" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                label="Selling Price (Rs.)"
                                                name="SELLING_PRICE"
                                                initialValue={this.props.initialValues.SELLING_PRICE}
                                            >
                                                <InputNumber style={inputStyle} step={0.01} placeholder="Enter the price" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                label="Intial Stock"
                                                name="STOCK"
                                                initialValue={this.props.initialValues.STOCK}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the stock',
                                                    },
                                                ]}
                                            >
                                                <InputNumber style={inputStyle} placeholder="Enter the stock" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                label="Unit"
                                                name="UNIT"
                                                initialValue={this.props.initialValues.UNIT}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select a unit',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select a unit"
                                                    style={inputStyle}
                                                >
                                                    <Option value="Pcs">PCS</Option>
                                                    <Option value="Kg">KG</Option>
                                                    <Option value="L">L</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Update Item
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            {/*

                */}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default UpdateEditStock;
