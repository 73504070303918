import React from 'react';
import { Form, Input, Button, Col, Row, message, Select, Divider } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

class UpdateMakeJuice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceOptions: [],
            rows: [], // Initialize with empty rows
        };

        this.formRef = React.createRef();
    }

    async componentDidMount() {
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({ referenceOptions });

            if (this.props.initialValues) {
                const { ITEMS } = this.props.initialValues;

                // Transform ITEMS into the expected format for rows
                const rows = ITEMS.map(item => ({
                    key: uuidv4(),
                    ROW_ITEM_ID: item.ITEM_ID,
                    QUANTITY: item.QUANTITY
                }));

                this.setState({ rows });

                console.log('rows:', rows);

                // Set form initial values
                this.formRef.current.setFieldsValue({
                    rows: Object.fromEntries(
                        rows.map(row => [row.key, { ROW_ITEM_ID: row.ROW_ITEM_ID, QUANTITY: row.QUANTITY }])
                    ),
                    EP_ITEM_ID: this.props.initialValues.EP_ITEM_ID,
                    EP_QUANTITY: this.props.initialValues.EP_QUANTITY,
                    COMMENTS: this.props.initialValues.COMMENTS,
                });
                }

        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsForReference');
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.STOCK > 0 ? `${ref.CODE} - ${ref.NAME} - ( ${ref.STOCK} Units )` : `${ref.CODE} - ${ref.NAME} - (Out of Stock)`,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    addRow = () => {
        this.setState((prevState) => {
            if (prevState.rows.length < 10) {
                return {
                    rows: [...prevState.rows, { key: uuidv4(), ROW_ITEM_ID: null, QUANTITY: null }],
                };
            }
            return prevState;
        });
    }

    removeRow = (key) => {
        this.setState((prevState) => {
            if (prevState.rows.length > 1) {
                return {
                    rows: prevState.rows.filter((row) => row.key !== key),
                };
            }
            return prevState;
        });
    }

    renderRow = (row, index) => {
        const { referenceOptions } = this.state;
        return (
            <Row key={row.key} gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        name={['rows', row.key, 'ROW_ITEM_ID']}
                        label={`Row Item ${index + 1}`}
                        rules={[{ required: true, message: 'Please select reference' }]}
                    >
                        <Select
                            placeholder="Select Reference"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option.key
                                    ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false) ||
                                (option.title
                                    ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false)
                            }
                        >
                            {referenceOptions.map((option) => (
                                <Option key={option.value} value={option.value} title={option.label}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={9}>
                    <Form.Item
                        name={['rows', row.key, 'QUANTITY']}
                        label={`Quantity ${index + 1}`}
                        rules={[{ required: true, message: 'Please enter quantity' }]}
                    >
                        <Input type="number" placeholder="Enter quantity" />
                    </Form.Item>
                </Col>
                {this.state.rows.length > 1 && (
                    <Col xs={24} sm={24} md={6} lg={3}>
                        <Button danger onClick={() => this.removeRow(row.key)}
                                style={{ marginTop: '32px' }} // Align button with inputs
                        >
                            -
                        </Button>
                    </Col>
                )}
            </Row>
        );
    }

    handleSubmit = async (values) => {
        console.log(values);
        try {
            let rememberedUser = Cookies.get('rememberedUser');
            let USER_ID = null;

            if (rememberedUser) {
                rememberedUser = JSON.parse(rememberedUser);
                USER_ID = rememberedUser.USER_ID;
            }

            const updatedValues = {
                ...values,
                CREATED_BY: USER_ID,
                MJ_ID: this.props.initialValues.MJ_ID,
                PREV_ITEM_ID: this.props.initialValues.EP_ITEM_ID,
                PREV_EP_QUANTITY: this.props.initialValues.EP_QUANTITY,
                OLD_ITEMS_AND_QUANTITIES: this.props.initialValues.ITEMS,
            };

            console.log('updatedValues:', updatedValues);

            const response = await axios.post('https://iontheway.store/api/updateMakeJuice', updatedValues);

            if (response.data.success) {
                message.success('Make Juice updated successfully');
                this.props.onClose();
                this.props.refreshTable();
                this.formRef.current.resetFields();
            } else {
                message.error('Failed to update Make Juice');
            }
        } catch (error) {
            console.error('Error updating Make Juice:', error);
            message.error('Internal server error');
        }
    };

    render() {
        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                {this.state.rows.map((row, index) => this.renderRow(row, index))}
                {this.state.rows.length < 10 && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item name="ROW_ADD_BUTTON">
                                <Button type="default" onClick={() => this.addRow()}>
                                    +
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={18}>
                        <Form.Item
                            name="EP_ITEM_ID"
                            label="End Product"
                            rules={[{ required: true, message: 'Please select end product' }]}
                        >
                            <Select
                                placeholder="Select End Product"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option.key
                                        ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false) ||
                                    (option.title
                                        ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false)
                                }
                            >
                                {this.state.referenceOptions.map((option) => (
                                    <Option key={option.value} value={option.value} title={option.label}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Form.Item
                            name="EP_QUANTITY"
                            label="Quantity"
                            rules={[{ required: true, message: 'Please enter quantity' }]}
                        >
                            <Input type="number" placeholder="Enter quantity" />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="COMMENTS"
                            label="Comments"
                        >
                            <Input.TextArea rows={2} placeholder="Enter Comments" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add Make Juice
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default UpdateMakeJuice;
