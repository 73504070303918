// /* eslint-disable */
import React, { Component } from 'react';
import {Button, Card, Col, Divider, Input, message, Modal, Popconfirm, Row, Select, Table, Tooltip} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import AddMakeJuice from './AddMakeJuice';
import UpdateMakeJuice from './UpdateMakeJuice';


const { Option } = Select;

const { Search } = Input;

class MakeJuice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            filteredTableData: [],
            isAddCustomerModalVisible: false,
            isUpdateCustomerModalVisible: false,
            selectedCustomer: null,
            referenceOptions: [],
            isViewItemModalVisible: false,
            selectedRefferenceItem: null,
        };

        // Bind methods
        this.handleUpdateShow = this.handleUpdateShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllCustomersHT = this.getAllCustomersHT.bind(this);
        this.toggleAddCustomerModal = this.toggleAddCustomerModal.bind(this);
        this.toggleUpdateCustomerModal = this.toggleUpdateCustomerModal.bind(this);
        this.handleAddCustomer = this.handleAddCustomer.bind(this);
        this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this);

    }

    async componentDidMount() {
        this.getAllCustomersHT();
        const referenceOptions = await this.fetchReferenceOptions();
        this.setState({referenceOptions});
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsForReference');
            //console.log('response', response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.CODE,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    handleSearch = (value) => {
        const { tableData } = this.state;

        // Filter the table data based on Treatment Group Code and Treatment Group Name
        const filteredData = tableData.filter((record) => {
            return record.NAME.toLowerCase().includes(value.toLowerCase());
        });

        this.setState({
            filteredTableData: filteredData,
        });

        if(filteredData.length === 0){
            message.info('No Make Juice found');
        }
    };

    handleUpdateShow(row) {
        this.setState({
            selectedCustomer: row,
            isUpdateCustomerModalVisible: true,
        });
    }

    handleDelete = async (Id) => {
        try {
            // Make an API call to deactivate the Treatment Group
            const response = await axios.post('https://iontheway.store/api/deactivateMakeJuice', {
                MJ_ID: Id,
            });

            if (response.data.success) {
                message.success('Treatment Group deleted successfully');
                // Refresh the table
                this.getAllCustomersHT();
            } else {
                message.error('Failed to delete Treatment Group');
            }
        } catch (error) {
            console.error('Error deleting Treatment Group:', error);
            message.error('Internal server error');
        }
    };

    showReferenceItem(itemId){
        //console.log('itemId', itemId);
        this.setState({
            selectedRefferenceItem: itemId,
            isViewItemModalVisible: true,
        });
    }


    async getAllCustomersHT() {
        this.setState({ loading: true });

        try {
            const response = await axios.post('https://iontheway.store/api/getAllMakeJuice');

            if (response.data.success) {
                const customers = response.data.result;

                this.setState({
                    tableData: customers,
                });
                console.log('tableData', this.state.tableData);
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    toggleAddCustomerModal() {
        this.setState({
            isAddCustomerModalVisible: !this.state.isAddCustomerModalVisible,
        });
    }

    toggleUpdateCustomerModal() {
        this.setState({
            isUpdateCustomerModalVisible: !this.state.isUpdateCustomerModalVisible,
        });
    }

    handleAddCustomer(values) {
        // Implement logic to add a new Treatment Group using the provided values
        //console.log('Add Treatment Group:', values);

        // Close the modal after adding Treatment Group
        this.toggleAddCustomerModal();
    }

    handleUpdateCustomer() {
        // Notify the parent component to refresh the table
        this.getAllCustomersHT();

        // Close the update modal
        this.toggleUpdateCustomerModal();
    }

    render() {
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Make Juice"
                                extra={
                                    <>
                                        <Search
                                            placeholder="Search by MJ Code"
                                            onSearch={this.handleSearch}
                                            style={{ width: 300, margin: '10px' }}
                                        />
                                        <Button className="primary" onClick={this.toggleAddCustomerModal} style={{ margin: '10px' }}>
                                            <PlusOutlined /> Add Make Juice
                                        </Button>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="MJ_ID"
                                        columns={[
                                            {
                                                title: 'MJ Code',
                                                dataIndex: 'MJ_ID',
                                                render: (text, row) => (
                                                    <span>MJ{row.MJ_ID.toString().padStart(8, '0')}</span>
                                                ),
                                            },
                                            {
                                                title: 'Row Products',
                                                render: (row) => (
                                                    row.ITEMS.length > 0 ? row.ITEMS.map((item) => (
                                                        <div key={item.ITEM_ID}>
                                                            <span>{item.ITEM_NAME} - {item.QUANTITY} Units</span>
                                                        </div>
                                                    )) : null
                                                ),
                                            },

                                            {
                                                title: 'End Product',
                                                dataIndex: 'EP_NAME',
                                                render: (text, row) => (
                                                    <span>{row.EP_NAME} - {row.EP_QUANTITY} Units</span>
                                                ),
                                            },
                                            {
                                                title: 'Comments',
                                                dataIndex: 'COMMENTS',
                                            },
                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Tooltip title="Edit">
                              <Button
                                  type="primary"
                                  icon={<EditOutlined />}
                                  size="large"
                                  style={buttonStyle}
                                  onClick={() => this.handleUpdateShow(row)}
                              />
                            </Tooltip>
                            <Divider type="vertical" style={{ height: '50px', display: 'flex', alignItems: 'center' }} />
                            <Tooltip title="Delete">
  <Popconfirm
      title="Are you sure you want to delete this Make Juice?"
      onConfirm={() => this.handleDelete(row.MJ_ID)}
      okText="Yes"
      cancelText="No"
  >
    <Button
        type="primary"
        danger
        icon={<DeleteOutlined />}
        size="large"
        style={buttonStyle}
    />
  </Popconfirm>
</Tooltip>
                          </span>
                                                ),
                                            },
                                        ]}
                                        dataSource={
                                            this.state.filteredTableData.length > 0
                                                ? this.state.filteredTableData
                                                : this.state.tableData
                                        }
                                        pagination={true}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="Add Make Juice"
                    visible={this.state.isAddCustomerModalVisible}
                    onCancel={this.toggleAddCustomerModal}
                    width={800}
                    footer={null}
                >
                    <AddMakeJuice
                        onClose={this.toggleAddCustomerModal}
                        refreshTable={this.getAllCustomersHT}
                    />
                </Modal>

                {/* Update Treatment Group Modal */}
                <Modal
                    title="Update Make Juice"
                    visible={this.state.isUpdateCustomerModalVisible}
                    onCancel={this.toggleUpdateCustomerModal}
                    width={800}
                    footer={null}
                >
                    {this.state.selectedCustomer && (
                        <UpdateMakeJuice
                            initialValues={this.state.selectedCustomer}
                            key={this.state.selectedCustomer.MJ_ID}
                            onClose={this.toggleUpdateCustomerModal}
                            refreshTable={this.getAllCustomersHT}
                        />
                    )}
                </Modal>
            </>
        );
    }
}

export default MakeJuice;
