// /* eslint-disable */
import React, { Component} from 'react';
import {
    Button, Card,
    Col, DatePicker, Divider, Form,
    Input,
    InputNumber,
    message,
    Modal, Popconfirm,
    Row,
    Select, Table, Tooltip
} from 'antd';
import axios from 'axios';
import EditTransactionsUpdate  from "./EditTransactionsUpdate";
import PaymentUpdate from "./PaymentUpdate";
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PrinterOutlined,ContainerOutlined} from "@ant-design/icons";
import Cookies from "js-cookie";
import {Redirect} from "react-router-dom";



const { Option } = Select;
const { RangePicker } = DatePicker;


class EditTransactions extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: false,
            filteredTableData: [],
            isUpdateModalVisible: false,
            isViewModalVisible: false,
            isPaymentModalVisible: false,
            selectedItem: null,
            tableData: [],
            customerOptions: [],
            searchCode: '',
            searchStatus: '',
            searchStartDate: null,
            searchEndDate: null,
        };

        // Bind methods
        this.handleViewShow = this.handleViewShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllTransactionsCashBook = this.getAllTransactionsCashBook.bind(this);
        this.toggleViewModal = this.toggleViewModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.togglePaymentModal = this.togglePaymentModal.bind(this);


    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getAllCustomers");
            //console.log("response", response);

            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    async componentDidMount() {
        const customerOptions = await this.fetchCustomerOptions();
        this.setState({customerOptions});

        this.getAllTransactionsCashBook();
        // this.fetchData();
    }

    handleClear = async () => {
        this.getAllTransactionsCashBook();
        this.formRef.current.resetFields();

    };

    handleSearch = async () => {
        this.setState({ loading: true });

        await this.getAllTransactionsCashBook();

        try {
            // Prepare the search criteria
            const searchData = {
                code: this.state.searchCode,
                status: this.state.searchStatus,
                startDate: this.state.searchStartDate,
                endDate: this.state.searchEndDate,
            };

            this.setState({
                searchCode: null,
                searchStatus: null,
                searchStartDate: null,
                searchEndDate: null,
            });

            //Search Using FrontEnd tableData array
            let filteredTableData = this.state.tableData;

            filteredTableData = filteredTableData.filter((item) => {
                let isValid = true;

                if (searchData.code) {
                    isValid = isValid && item.CODE.toLowerCase().includes(searchData.code.toLowerCase());
                }

                if (searchData.status) {
                    isValid = isValid && item.TYPE === searchData.status;
                }

                if (searchData.startDate) {
                    isValid = isValid && new Date(item.DATE) >= new Date(searchData.startDate);
                }

                if (searchData.endDate) {
                    isValid = isValid && new Date(item.DATE) <= new Date(searchData.endDate);
                }

                return isValid;
            }
            );
            this.state.tableData = filteredTableData;

        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    };



    handleViewShow(row) {
        console.log('row', row);
        if(row.TYPE === 'Payment'){
            this.setState({
                selectedItem: row,
                isPaymentModalVisible: true,
            });
        }
        else {
            this.setState({
                selectedItem: row,
                isViewModalVisible: true,
            });
        }

    }


    handleDelete = async (id,all,itemDel) => {
        //console.log('id', id);
        //console.log('all', all);
        try {
            // Make an API call to deactivate the customer
            const response = await axios.post('https://iontheway.store/api/deactivateTransaction', {
                TRANSACTION_ID: id,
                ALL: all,
                ITEM_DEL: itemDel
            });

            if (response.data.success) {
                message.success('Transaction deleted successfully');
                // Refresh the table
                await this.getAllTransactionsCashBook();
            } else {
                message.error('Failed to delete customer');
            }
        } catch (error) {
            console.error('Error deleting customer:', error);
            message.error('Internal server error');
        }
    };

    handlePrintBill = async (row) => {
        //console.log('row', row);
        try {
            const response = await axios.post('https://iontheway.store/api/generateBillByGenerator', {
                TRANSACTION_ID: row.TRANSACTION_ID,
            });

            if (response.data.success) {
                                const pdfData = response.data.data;
                                const pdfBlob = new Blob([Buffer.from(pdfData, 'base64')], { type: 'application/pdf' });
                                const pdfUrl = URL.createObjectURL(pdfBlob);

                                // Create an iframe to load the PDF
                                const iframe = document.createElement('iframe');
                                iframe.style.display = 'none';
                                iframe.src = pdfUrl;
                                document.body.appendChild(iframe);

                                // Print the PDF
                                iframe.contentWindow.print();

                                // Remove the iframe after printing
                                iframe.onload = function () {
                                    setTimeout(() => {
                                        document.body.removeChild(iframe);
                                    }, 90000); // Adjust the timeout as needed
                                };
            } else {
                console.error('Error generating invoice:', response.data.message);
                // Handle error, e.g., display an error message
            }
        } catch (error) {
            console.error('Error generating invoice:', error.message);
            // Handle error, e.g., display an error message
        }
    }

    handlePrint = async (row) => {
        //console.log('row', row);
        try {
            const response = await axios.post('https://iontheway.store/api/generateInvoiceByGenerator', {
                TRANSACTION_ID: row.TRANSACTION_ID,
            });

            // const response = await axios.post('https://iontheway.store/api/generateBillByGenerator', {
            //     TRANSACTION_ID: row.TRANSACTION_ID,
            // });

            if (response.data.success) {
                const blob = new Blob([new Uint8Array(atob(response.data.data).split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                // Removed the line that sets the 'download' attribute
                link.target = '_blank'; // This line makes it open in a new tab
                link.click();
            } else {
                console.error('Error generating invoice:', response.data.message);
                // Handle error, e.g., display an error message
            }
        } catch (error) {
            console.error('Error generating invoice:', error.message);
            // Handle error, e.g., display an error message
        }
    };


    async getAllTransactionsCashBook() {
        this.setState({ loading: true });
        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }


        try {
            let response;
            if(ROLE === 'ADMIN') {
                response = await axios.post('https://iontheway.store/api/getAllTransactionsCashBook');
            }
            else {
                response = await axios.post('https://iontheway.store/api/getAllTransactionsCashBookByUser');
            }

            if (response.data.success) {
                const items = response.data.result;
                console.log('items', items);

                this.state.tableData = items;
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    toggleViewModal() {
        this.setState({
            isViewModalVisible: !this.state.isViewModalVisible,
            selectedItem: null,
        });
    }

    togglePaymentModal() {
        this.setState({
            isPaymentModalVisible: !this.state.isPaymentModalVisible,
            selectedItem: null,
        });
    }

    filterDue = async () => {
        this.setState({ loading: true });
        await this.getAllTransactionsCashBook();
        let filteredDueItems = [];

        this.state.tableData.map((item) => {
            if(item.DUE === true && item.TYPE !== 'Expenses'){
                filteredDueItems.push(item);
            }
        }
        );
        this.state.tableData = filteredDueItems;
        this.setState({ loading: false });

    }


    render() {

        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        const buttonStylePrint = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            backgroundColor: '#52c41a',
            display: 'flex',
            color: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
        };

        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }

        if(ROLE === 'USER_MILL'){
            return <Redirect to="/dashboard" />
        }

        const showDeleteAllPaymentsConfirm = (itemId) => {
            Modal.confirm({
                title: 'Do you want to delete the transaction with its all payments?',
                icon: <ExclamationCircleOutlined />,
                width: '600px',
                content: (
                    <div>
                        'This action will permanently delete the transaction and all associated payments.'
                        <Button
                            type="primary"
                            style={{ float: 'right', marginTop: '20px' }}
                            onClick={() => { this.handleDelete(itemId, true, true); Modal.destroyAll(); }}
                        >
                            Yes, Delete All Payments
                        </Button>
                        <Button
                            danger
                            style={{ float: 'left', marginTop: '20px' }}
                            onClick={() => { this.handleDelete(itemId, false, true); Modal.destroyAll(); }}
                        >
                            Only Delete Transaction
                        </Button>
                    </div>
                ),
                footer: null,
                closable: true,
            });
        };

        const paginationConfig = {
            pageSize: 50,
            total: this.state.tableData.length,
            showSizeChanger: false,
        };
        return (
            <>
                {/* Search Filter */}
                <div>
                    <Form
                        ref={this.formRef}
                        initialValues={{
                            searchCode: null,
                            searchStatus: null,
                            searchDateRange: null,
                        }}
                        onFinish={this.handleSearch}
                    >
                        <Row gutter={[16, 16]} justify="left" align="top">
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchCode">
                                    <Input
                                        placeholder="Search by Transaction Code"
                                        onChange={(e) => this.setState({ searchCode: e.target.value })}
                                        style={{ width: '100%'}}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchCustomer">
                                    <Select
                                        placeholder="Select Transaction Type"
                                        allowClear
                                        showSearch
                                        onChange={(value) => this.setState({ searchStatus: value })}
                                        filterOption={(input, option) =>
                                            (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                            (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                        }
                                    >
                                        <Option key="Selling" value="Selling" title="Selling">
                                            Selling
                                        </Option>
                                        <Option key="Buying" value="Buying" title="Buying">
                                            Buying
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchDateRange">
                                    <RangePicker
                                        style={{ width: '100%' }}
                                        onChange={(dates) => {
                                            this.setState({
                                                searchStartDate: dates && dates.length === 2 ? dates[0].format('YYYY-MM-DD') : null,
                                                searchEndDate: dates && dates.length === 2 ? dates[1].format('YYYY-MM-DD') : null,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item>
                                    <Button type="default" htmlType="submit" style={{ marginRight: '8px' }}>
                                        Filter
                                    </Button>
                                    {/*<Button type="primary" onClick={this.filterDue} style={{ marginRight: '8px' }}>*/}
                                    {/*    Filter Due*/}
                                    {/*</Button>*/}
                                    <Button type="default" danger onClick={this.handleClear} style={{ marginRight: '8px' }}>
                                        Clear
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row gutter={[16, 16]} justify="left" align="top">*/}
                        {/*    <Col xs={24} sm={24} md={24} lg={6}>*/}
                        {/*        <Form.Item*/}
                        {/*            name="shareholder"*/}
                        {/*        >*/}
                        {/*            <Select placeholder="Select Share Holder" allowClear showSearch onChange={this.filterByShareholder}*/}
                        {/*                    filterOption={(input, option) =>*/}
                        {/*                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||*/}
                        {/*                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)*/}
                        {/*                    }>*/}
                        {/*                {this.state.partnerOptions.map((option) => (*/}
                        {/*                    <Option key={option.value} value={option.value} title={option.label}>*/}
                        {/*                        {option.label}*/}
                        {/*                    </Option>*/}
                        {/*                ))}*/}
                        {/*            </Select>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*    <Col xs={24} sm={24} md={24} lg={6}>*/}
                        {/*        <Button type="default" onClick={this.toggleCountCards}>*/}
                        {/*            {this.state.showCountCards ? 'Hide Summary' : 'Show Summary'}*/}
                        {/*        </Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Form>

                </div>

                {/* Cards and Tables */}
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title='Transactions'
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        rowKey="TRANSACTION_ID"
                                        style={{ margin: '15px' }}
                                        columns={[
                                            {
                                                title: 'Transaction Code',
                                                dataIndex: 'CODE',
                                                render: (text, record) => (
                                                    record.DUE === true ? (
                                                        <span style={{ color: 'red' }}>{record.CODE} - {record.NO_OF_LATE_DAYS} Days Late</span>
                                                    ) : (
                                                        <span>{record.CODE}</span>
                                                    )
                                                ),
                                            },

                                            {
                                                title: 'Method',
                                                dataIndex: 'METHOD',
                                                render: (text, record) => (
                                                    record.METHOD !== null ? (
                                                        <span>{record.METHOD}</span>
                                                    ) : (
                                                        <span>N / A</span>
                                                    )
                                                ),
                                            },
                                            {
                                                title: 'Date',
                                                dataIndex: 'DATE',
                                                render: (row) => (
                                                    <span> {new Date(row).toLocaleDateString()}</span>
                                                ),
                                            },

                                            {
                                                title: 'Amount',
                                                dataIndex: 'PAYMENT_AMOUNT',
                                                render: (text, record) => {
                                                    if (record.TYPE === 'Expenses' || record.TYPE === 'Buying' || record.TYPE === 'B Payment') {
                                                        return <div style={{ color: 'red' }}>- Rs. {record.PAYMENT_AMOUNT}</div>;
                                                    } else {
                                                        return <div style={{ color: 'green' }}>+ Rs. {record.PAYMENT_AMOUNT}</div>;
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Edit">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size="large"
                        style={buttonStyle}
                        onClick={() => this.handleViewShow(row)}
                    />
                </Tooltip>
            {/*                                                {row.TYPE !== 'Payment' ? (*/}
            {/*    <Divider*/}
            {/*        type="vertical"*/}
            {/*        style={{ height: '50px', display: 'flex', alignItems: 'center' }}*/}
            {/*    />*/}
            {/*) : null}*/}
            {/*                                                {row.TYPE !== 'Payment' ? (*/}
            {/*    <Tooltip title="Print">*/}
            {/*        <Button*/}
            {/*            type="default"*/}
            {/*            icon={<PrinterOutlined />}*/}
            {/*            disabled={row.TYPE === 'Payment'}*/}
            {/*            size="large"*/}
            {/*            style={buttonStylePrint}*/}
            {/*            onClick={() => this.handlePrint(row)}*/}
            {/*        />*/}
            {/*    </Tooltip>*/}
            {/*) : null}*/}
                                                            {row.TYPE !== 'Payment' ? (
                                                                <Divider
                                                                    type="vertical"
                                                                    style={{ height: '50px', display: 'flex', alignItems: 'center' }}
                                                                />
                                                            ) : null}
                                                            {row.TYPE !== 'Payment' ? (
                                                                <Tooltip title="Print Bill">
                                                                    <Button
                                                                        type="default"
                                                                        icon={<ContainerOutlined />}
                                                                        disabled={row.TYPE === 'Payment'}
                                                                        size="large"
                                                                        style={buttonStylePrint}
                                                                        onClick={() => this.handlePrintBill(row)}
                                                                    />
                                                                </Tooltip>
                                                            ) : null}
                <Divider
                    type="vertical"
                    style={{ height: '50px', display: 'flex', alignItems: 'center' }}
                />
                <Tooltip title="Delete">
                    <Popconfirm
                        title={`Are you sure you want to delete this?`}
                        onConfirm={ row.TYPE === 'Payment' ? () => this.handleDelete(row.TRANSACTION_ID, false, false) : () => showDeleteAllPaymentsConfirm(row.TRANSACTION_ID)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            danger
                            type="primary"
                            icon={<DeleteOutlined />}
                            size="large"
                            style={buttonStyle}
                        />
                    </Popconfirm>
                </Tooltip>
            </span>

                                                ),
                                            },

                                        ]}
                                        dataSource={this.state.tableData}
                                        pagination={paginationConfig}
                                        loading={this.state.loading}
                                        // expandedRowRender={(record) => (
                                        //     record.PAYMENTS && record.PAYMENTS.length > 0 ? (
                                        //         <Table
                                        //             size="small"
                                        //             rowKey="TRANSACTION_ID"
                                        //             columns={[
                                        //                 {
                                        //                     title: 'Transaction Code',
                                        //                     dataIndex: 'CODE',
                                        //                 },
                                        //                 {
                                        //                     title: 'Status',
                                        //                     dataIndex: 'STATUS',
                                        //                 },
                                        //                 {
                                        //                     title: 'Date',
                                        //                     dataIndex: 'DATE',
                                        //                     render: (row) => (
                                        //                         <span> {new Date(row).toLocaleDateString()}</span>
                                        //                     ),
                                        //                 },
                                        //                 {
                                        //                     title: 'Method',
                                        //                     dataIndex: 'METHOD'
                                        //                 },
                                        //                 {
                                        //                     title: 'Amount',
                                        //                     dataIndex: 'PAYMENT_AMOUNT'
                                        //                 },
                                        //                 {
                                        //                     title: 'Note',
                                        //                     dataIndex: 'COMMENTS'
                                        //                 },
                                        //             ]
                                        //             }
                                        //             dataSource={record.PAYMENTS}
                                        //             pagination={false}
                                        //         >
                                        //         </Table>
                                        //     ) : null
                                        // )}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>

                 {/*View Transaction Modal*/}
                <Modal
                    title="Update Transaction"
                    visible={this.state.isViewModalVisible}
                    onCancel={this.toggleViewModal}
                    footer={null}
                    width={1250}
                >
                    {this.state.selectedItem && (
                        <EditTransactionsUpdate
                            key={this.state.selectedItem.TRANSACTION_ID} // Pass a key to ensure a new instance is created
                            initialValues={this.state.selectedItem}
                            onCancel={this.toggleViewModal}
                            onUpdate={this.getAllTransactionsCashBook}
                        />
                    )}
                </Modal>

                {/*Payment Modal*/}
                <Modal
                    title="Update Payment"
                    visible={this.state.isPaymentModalVisible}
                    onCancel={this.togglePaymentModal}
                    footer={null}
                    width={1250}
                >
                    {this.state.selectedItem && (
                        <PaymentUpdate
                            key={this.state.selectedItem.TRANSACTION_ID} // Pass a key to ensure a new instance is created
                            initialValues={this.state.selectedItem}
                            onCancel={this.togglePaymentModal}
                            onUpdate={this.getAllTransactionsCashBook}
                        />
                    )}
                </Modal>




            </>
        );
    }
}

export default EditTransactions;
