// EditStock.js
import React, { Component } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tooltip,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    EyeOutlined,
    PrinterOutlined, // Imported for Print Label button
} from '@ant-design/icons';
import axios from 'axios';
import UpdateEditStock from './UpdateEditStock';
import Cookies from 'js-cookie';
import { Redirect, withRouter } from 'react-router-dom';
import Barcode from 'react-barcode';
import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode'; // Imported for barcode SVG generation

const { Option } = Select;
const { Search } = Input;

class EditStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            filteredTableData: [],
            isUpdateCustomerModalVisible: false,
            isViewItemModalVisible: false,
            selectedCustomer: null,
            isBarcodeModalVisible: false, // State for barcode modal
            selectedBarcodeItem: null,    // Item selected for barcode generation
            isLabelModalVisible: false,    // State for label printing modal
            selectedLabelItem: null,       // Item selected for label printing
        };

        // Bind methods
        this.handleUpdateShow = this.handleUpdateShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllItems = this.getAllItems.bind(this);
        this.toggleUpdateCustomerModal = this.toggleUpdateCustomerModal.bind(this);
        this.handleShowBarcode = this.handleShowBarcode.bind(this);
        this.handleCloseBarcodeModal = this.handleCloseBarcodeModal.bind(this);
        this.generatePDF = this.generatePDF.bind(this);
        this.handleShowLabel = this.handleShowLabel.bind(this);
        this.handleCloseLabelModal = this.handleCloseLabelModal.bind(this);
        this.printLabel = this.printLabel.bind(this);

        this.barcodeRef = React.createRef(); // Reference for the barcode
    }

    async componentDidMount() {
        this.getAllItems();
    }

    handleSearch = (value) => {
        const { tableData } = this.state;

        // Filter the table data based on Item Code and Item Name
        const filteredData = tableData.filter((record) => {
            return (
                record.CODE.toLowerCase().includes(value.toLowerCase()) ||
                record.NAME.toLowerCase().includes(value.toLowerCase())
            );
        });

        this.setState({
            filteredTableData: filteredData,
        });

        if (filteredData.length === 0) {
            message.info('No Item Found');
        }
    };

    handleUpdateShow(row) {
        this.setState({
            selectedCustomer: row,
            isUpdateCustomerModalVisible: true,
        });
    }

    handleDelete = async (Id) => {
        try {
            // Make an API call to deactivate the Item
            const response = await axios.post('https://iontheway.store/api/deactivateItem', {
                ITEM_ID: Id,
            });

            if (response.data.success) {
                message.success('Item deleted successfully');
                // Refresh the table
                await this.getAllItems();
            } else {
                message.error('Failed to delete Item');
            }
        } catch (error) {
            console.error('Error deleting Item:', error);
            message.error('Internal server error');
        }
    };

    async getAllItems() {
        this.setState({ loading: true });

        try {
            const response = await axios.post('https://iontheway.store/api/getAllItems');

            if (response.data.success) {
                const customers = response.data.result;
                console.log('customers', customers);

                this.setState({
                    tableData: customers,
                    filteredTableData: [], // Reset filtered data
                });
            } else {
                message.error('Failed to fetch items.');
            }
        } catch (error) {
            console.error('Error fetching items:', error);
            message.error('Internal server error');
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    toggleUpdateCustomerModal() {
        this.setState({
            isUpdateCustomerModalVisible: !this.state.isUpdateCustomerModalVisible,
        });
    }

    handleShowBarcode(item) {
        this.setState({
            selectedBarcodeItem: item,
            isBarcodeModalVisible: true,
        });
    }

    handleCloseBarcodeModal() {
        this.setState({
            selectedBarcodeItem: null,
            isBarcodeModalVisible: false,
        });
    }

    generatePDF() {
        const { selectedBarcodeItem } = this.state;
        if (!selectedBarcodeItem) {
            message.error('No item selected for barcode generation.');
            return;
        }

        const doc = new jsPDF();

        // Create a temporary div to render the barcode as SVG
        const svgElement = this.barcodeRef.current.querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svgElement);

        // Convert SVG to Image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const imgData = canvas.toDataURL('image/png');

            // Add the image to PDF
            doc.addImage(imgData, 'PNG', 10, 10, 180, 60);

            // Open PDF in a new tab
            const pdfUrl = doc.output('bloburl');
            window.open(pdfUrl, '_blank');
        };

        img.src = 'data:image/svg+xml;base64,' + window.btoa(svgData);
    }

    handleShowLabel(item) {
        this.setState({
            selectedLabelItem: item,
            isLabelModalVisible: true,
        });
    }

    handleCloseLabelModal() {
        this.setState({
            selectedLabelItem: null,
            isLabelModalVisible: false,
        });
    }

    generateBarcodeSVG(code) {
        // Create a temporary canvas to generate the barcode
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, code, {
            format: 'CODE128',
            displayValue: false,
            width: 1,
            height: 30,
            margin: 0,
        });

        // Get the PNG data URL from the canvas
        const pngDataUrl = canvas.toDataURL('image/png');

        // Embed the PNG image in an SVG
        const svgString = `
      <image href="${pngDataUrl}" height="30" width="100%" />
    `;

        return svgString;
    }

    printLabel() {
        const { selectedLabelItem } = this.state;
        if (!selectedLabelItem) {
            message.error('No item selected for label printing.');
            return;
        }

        // Create a new window
        const printWindow = window.open('', 'PRINT', 'height=300,width=200');

        // Define the HTML for the label
        const labelHTML = `
      <html>
  <head>
    <title>Print Barcode Label</title>
    <style>
      @media print {
        @page {
          size: 30mm 20mm; /* Width x Height */
          margin: 0;
        }
        body {
          margin: 0;
          padding: 0;
        }
        /* Ensure proper sizing in the window */
        .label-container {
          width: 30mm;
          height: 20mm;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
         
        }
        .barcode {
          width: 100%;
          height: auto;
          margin-top: 15px; /* Adjusted margin */
        }
        .code-text {
          font-size: 4mm;
          text-align: center;
          margin-bottom: 15px; /* Adjusted margin */
        }
      }
    </style>
  </head>
  <body>
    <div class="label-container">
      <svg class="barcode" xmlns="http://www.w3.org/2000/svg" width="100%" height="auto">
        <!-- Barcode Image -->
        ${this.generateBarcodeSVG(selectedLabelItem.CODE)}
      </svg>
      <div class="code-text">${selectedLabelItem.CODE}</div>
    </div>
  </body>
</html>

    `;

        // Write the HTML to the new window
        printWindow.document.write(labelHTML);
        printWindow.document.close();

        // Wait for the content to load before printing
        printWindow.onload = function () {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    }

    render() {
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }

        if (ROLE === 'USER_MILL') {
            return <Redirect to="/dashboard" />;
        }

        const columns = [
            {
                title: 'Item Code',
                dataIndex: 'CODE',
            },
            {
                title: 'Item Name',
                dataIndex: 'NAME',
            },
            {
                title: 'Buying Price',
                dataIndex: 'BUYING_PRICE',
                align: 'right',
                render: (text, record) => {
                    return 'Rs ' + record.BUYING_PRICE.toFixed(2);
                },
            },
            {
                title: 'Sell Price',
                dataIndex: 'SELLING_PRICE',
                align: 'right',
                render: (text, record) => {
                    return 'Rs ' + record.SELLING_PRICE.toFixed(2);
                },
            },
            {
                title: 'Current Stock',
                dataIndex: 'STOCK',
                align: 'center',
                render: (text, record) => {
                    return record.STOCK.toFixed(2);
                },
            },
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                align: 'left',
            },
            {
                title: 'Action',
                width: '240px', // Increased width to accommodate the new button
                align: 'center',
                render: (row) => (
                    <span style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
            <Tooltip title="Edit">
              <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size="large"
                  style={buttonStyle}
                  onClick={() => this.handleUpdateShow(row)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                  title="Are you sure you want to delete this Item?"
                  onConfirm={() => this.handleDelete(row.ITEM_ID)}
                  okText="Yes"
                  cancelText="No"
              >
                <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    size="large"
                    style={buttonStyle}
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Generate Barcode">
              <Button
                  type="default"
                  icon={<EyeOutlined />}
                  size="large"
                  style={buttonStyle}
                  onClick={() => this.handleShowBarcode(row)}
              />
            </Tooltip>
            <Tooltip title="Print Label">
              <Button
                  type="default"
                  icon={<PrinterOutlined />}
                  size="large"
                  style={buttonStyle}
                  onClick={() => this.handleShowLabel(row)}
              />
            </Tooltip>
          </span>
                ),
            },
        ];

        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Items"
                                extra={
                                    <>
                                        <Search
                                            placeholder="Search by Code or Name"
                                            onSearch={this.handleSearch}
                                            style={{ width: 300, margin: '10px' }}
                                            allowClear
                                        />
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="CODE"
                                        columns={columns}
                                        dataSource={
                                            this.state.filteredTableData.length > 0
                                                ? this.state.filteredTableData
                                                : this.state.tableData
                                        }
                                        pagination={true}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>

                {/* Update Item Modal */}
                <Modal
                    title="Update Item"
                    visible={this.state.isUpdateCustomerModalVisible}
                    onCancel={this.toggleUpdateCustomerModal}
                    footer={null}
                    width={1100}
                >
                    {this.state.selectedCustomer && (
                        <UpdateEditStock
                            key={this.state.selectedCustomer.ITEM_ID}
                            initialValues={this.state.selectedCustomer}
                            type={this.state.formType}
                            onUpdate={this.getAllItems}
                            onCancel={this.toggleUpdateCustomerModal}
                        />
                    )}
                </Modal>

                {/* Barcode Modal */}
                <Modal
                    title={`Barcode for Item Code: ${this.state.selectedBarcodeItem ? this.state.selectedBarcodeItem.CODE : ''}`}
                    visible={this.state.isBarcodeModalVisible}
                    onCancel={this.handleCloseBarcodeModal}
                    footer={[
                        <Button key="back" onClick={this.handleCloseBarcodeModal}>
                            Cancel
                        </Button>,
                        <Button key="open" type="primary" onClick={this.generatePDF}>
                            Open PDF in New Tab
                        </Button>,
                    ]}
                    width={400}
                >
                    {this.state.selectedBarcodeItem && (
                        <div ref={this.barcodeRef} style={{ textAlign: 'center' }}>
                            <Barcode value={this.state.selectedBarcodeItem.CODE} />
                            <p>{this.state.selectedBarcodeItem.CODE}</p>
                        </div>
                    )}
                </Modal>

                {/* Label Printing Modal */}
                <Modal
                    title={`Print Label for Item Code: ${this.state.selectedLabelItem ? this.state.selectedLabelItem.CODE : ''}`}
                    visible={this.state.isLabelModalVisible}
                    onCancel={this.handleCloseLabelModal}
                    footer={[
                        <Button key="back" onClick={this.handleCloseLabelModal}>
                            Cancel
                        </Button>,
                        <Button key="print" type="primary" onClick={this.printLabel}>
                            Print Label
                        </Button>,
                    ]}
                    width={300}
                >
                    {this.state.selectedLabelItem && (
                        <div style={{ textAlign: 'center' }}>
                            <Barcode value={this.state.selectedLabelItem.CODE} width={1} height={50} displayValue={false} />
                            <p style={{ fontSize: '12px', marginTop: '5px' }}>{this.state.selectedLabelItem.CODE}</p>
                        </div>
                    )}
                </Modal>
            </>
        );
    }
}

export default withRouter(EditStock);
