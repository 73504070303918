// /* eslint-disable */
import React, {Component} from "react";
import {
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Select,
    Button,
    message,
    DatePicker,
    Input, Divider, Switch, Table
} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import moment from 'moment';
import {DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import {Redirect} from "react-router-dom";

const { Option } = Select;

export default class AddReturn extends Component {

    constructor(props) {
        super(props);
        this.state = {

            customerOptions: [],
            tableData: this.props.initialValues ? this.props.initialValues.ITEMS : [],
            itemMaxQuantity: 999999999,
            currentItemName: '',
            showOverMaxQuantityWarning: false,
            currentItemCode: '',
            ReferenceOptions: [],
            type: this.props.initialValues ? this.props.initialValues.TYPE : 'Returns',
            Unit: 'Unit',
            method: 'Cash',
            itemSubTotal: 0,
            TransactionOptions: [],

        };
    }

    formRef = React.createRef();



    handleCalculateSubTotal = () => {
        //get SUB_TOTAL value and apply discount if available
        const form = this.formRef.current;
        if (form && this.state.discount && this.state.itemSubTotal) {
            const subTotal = this.state.itemSubTotal;
            const discount = form.getFieldValue('DISCOUNT') || 0;

            if(form.getFieldValue('DISCOUNT_TYPE') === 'Percentage'){
                const total = subTotal - (subTotal * discount / 100);
                form.setFieldsValue({ SUB_TOTAL: total.toFixed(2) });
            }
            else {
                const total = subTotal - discount;
                form.setFieldsValue({SUB_TOTAL: total.toFixed(2)});
            }
        }
        else {
            form.setFieldsValue({ SUB_TOTAL: this.state.itemSubTotal.toFixed(2) });
        }
    }

    handleCalculateTotal = () => {
        const form = this.formRef.current;
        if (form) {
            const soldAmount = form.getFieldValue('AMOUNT') || 0;
            const sharePercentage = form.getFieldValue('SHARE_PERCENTAGE') || 0;

            const total = soldAmount * sharePercentage / 100;

            form.setFieldsValue({ SHARE_VALUE: total });
        }
    };

    handleTransactionChange = async (value) => {
        const form = this.formRef.current;
        try {
            const response = await axios.post('https://iontheway.store/api/getTransactionDetails', {
                TRANSACTION_ID: value,
            });

            if (response.data.success) {
                //console.log("response", response);
                form.setFieldsValue({ SUB_TOTAL: response.data.result.SUB_TOTAL });
                this.setState({
                    tableData: response.data.result.ITEMS,
                    itemSubTotal: response.data.result.SUB_TOTAL,
                });
            } else {
                message.error('Failed to fetch Transaction Details');
            }
        } catch (error) {
            console.error('Error fetching Transaction Details:', error);
            message.error('Internal server error');
        }
    }




    async componentDidMount() {
        const form = this.formRef.current;

        // Fetch customer options when the component mounts
        // const customerOptions = await this.fetchCustomerOptions();
        // this.setState({ customerOptions });
        const ReferenceOptions = await this.fetchReferenceOptions();
        this.setState({ ReferenceOptions });
        const TransactionOptions = await this.fetchTransactionOptions();
        this.setState({ TransactionOptions });
        form.getFieldInstance('ITEM').focus();
        document.addEventListener('keydown', this.handleKeyDown);

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        // Check if both Ctrl and Enter keys are pressed
        if (e.ctrlKey && e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.handleSubmit(); // Call your form submission function
        }
        // go to ITEM field when Ctrl + I is pressed
        if (e.ctrlKey && e.key === 'i') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('ITEM').focus(); // Call your form submission function
        }
        // go to PRICE field when Ctrl + P is pressed
        if (e.ctrlKey && e.key === 'p') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('PRICE').focus(); // Call your form submission function
        }
        // go to QUANTITY field when Ctrl + Q is pressed
        if (e.ctrlKey && e.key === 'q') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('QUANTITY').focus(); // Call your form submission function
        }
        // go to TOTAL field when Ctrl + T is pressed
        if (e.ctrlKey && e.key === 't') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('TOTAL').focus(); // Call your form submission function
        }
        // go to SUB_TOTAL field when Ctrl + S is pressed
        if (e.ctrlKey && e.key === 's') {
            e.preventDefault(); // Prevent the default behavior (e.g., new line in text area)
            this.formRef.current.getFieldInstance('SUB_TOTAL').focus(); // Call your form submission function
        }

    };


    async fetchReferenceOptions1() {
        try {
            const response = await axios.post("https://iontheway.store/api/getItemsForReference");
            console.log("response", response);
            this.state.ReferenceOptions = response.data.result.map((ref) => ({
                    value: ref.ITEM_ID,
                    label: ref.STOCK > 0 ? `${ref.ITEM_ID}.${ref.NAME} - ( ${ref.STOCK} units )` : `${ref.ITEM_ID}.${ref.NAME} - (Out of Stock)`,
                }
            ));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getItemsForReference");
            console.log("response", response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                code: ref.CODE,
                label: ref.STOCK > 0 ? `${ref.CODE} - ${ref.NAME} - ( ${ref.STOCK} )` : `${ref.CODE} - ${ref.NAME} - (Out of Stock)`,
            }));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }

    handleCalculateTotal = () => {
        const form = this.formRef.current;
        if (form) {
            const price = form.getFieldValue('PRICE') || 0;
            const quantity = form.getFieldValue('QUANTITY') || 0;

            const total = price * quantity;
            //limit to 2 decimal places
            const Fixedtotal = total.toFixed(2);

            form.setFieldsValue({ TOTAL: Fixedtotal });
            if(quantity > this.state.itemMaxQuantity){
                this.setState({ showOverMaxQuantityWarning: true });
            } else {
                this.setState({ showOverMaxQuantityWarning: false });
            }
        }
    }



    handleReferenceChange = async (value) => {
        //console.log(`selected ${value}`);
        const form = this.formRef.current;
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsDetailsForTransaction', {
                ITEM_ID: value,
            });

            if (response.data.success) {
                //console.log("response", response);
                if(this.state.type === 'Selling'){
                    form.setFieldsValue({ PRICE: response.data.result.SELLING_PRICE });
                }
                else{
                    form.setFieldsValue({ PRICE: response.data.result.BUYING_PRICE });
                }

                this.setState({
                        itemMaxQuantity: response.data.result.STOCK,
                        currentItemName: response.data.result.NAME,
                        currentItemCode: response.data.result.CODE,
                        Unit: response.data.result.UNIT,
                        showOverMaxQuantityWarning: false,
                    }
                );
                form.resetFields(['QUANTITY', 'TOTAL']);
                //focus on QUANTITY field
                form.getFieldInstance('QUANTITY').focus();


            } else {
                message.error('Failed to fetch Item Details');
            }
        } catch (error) {
            console.error('Error fetching Item Details:', error);
            message.error('Internal server error');
        }
    };


    handleAddItemToTable = async () => {
        const form = this.formRef.current;
        try {

            if (form.getFieldValue('ITEM') !== undefined && form.getFieldValue('PRICE') !== undefined && form.getFieldValue('QUANTITY') !== undefined && form.getFieldValue('TOTAL') !== undefined) {

                const currentItem = {
                    ITEM_ID: form.getFieldValue('ITEM'),
                    ITEM_CODE: this.state.currentItemCode,
                    ITEM_NAME: this.state.currentItemName,
                    PRICE: parseFloat(form.getFieldValue('PRICE')),
                    QUANTITY: parseFloat(form.getFieldValue('QUANTITY')),
                    TOTAL: parseFloat(form.getFieldValue('TOTAL')),
                };

                form.resetFields(['DUE_AMOUNT']);
                await this.setState((prevState) => ({tableData: [...prevState.tableData, currentItem]}));


                form.resetFields(['ITEM', 'PRICE', 'QUANTITY', 'TOTAL']);

                form.getFieldInstance('ITEM').focus();
                form.setFieldsValue({ITEM: undefined});


                // Calculate Sub Total and update the form
                const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
                this.state.itemSubTotal = subTotal;
                form.setFieldsValue({SUB_TOTAL: subTotal.toFixed(2)});
                this.handleCalculateSubTotal();
                console.log('subTotal', subTotal);
                console.log('tableData', this.state.tableData);


            } else {
                message.error('Please fill all the fields');
            }
        }
        catch (error) {
            console.error('Error adding item to table:', error);
            message.error('Internal server error');
        }
    };


    handleSubmit = async () => {
        const form = this.formRef.current;
        try {
            // Validate and get form values
            const values = await form.validateFields();

            // Retrieve USER_ID from rememberedUser
            const rememberedUser = Cookies.get('rememberedUser');
            const USER_ID = rememberedUser ? JSON.parse(rememberedUser).USER_ID : null;

            const transactionData = {
                ...values,
                CREATED_BY: USER_ID,
                ITEMS: this.state.tableData,
            };

            console.log('Return Data:', transactionData);

            const response = await axios.post('https://iontheway.store/api/addReturn', transactionData);

            if (response.data.success) {
                message.success('Return added successfully');
                this.formRef.current.resetFields();
                this.setState({ tableData: [] });
                await this.fetchReferenceOptions1();
                this.setState({ method: 'Cash' , discount: false });
                this.props.onCancel();
                this.props.onUpdate();
            } else {
                message.error('Failed to add Return');
            }
            form.getFieldInstance('ITEM').focus();
        } catch (error) {
            console.error('Error submitting Return:', error);
            message.error('Internal server error');
        }
    };


    handleDeleteItem = async (record) => {
        await this.setState((prevState) => ({
            tableData: prevState.tableData.filter((item) => item !== record),
        }));

        // Calculate Sub Total and update the form
        const form = this.formRef.current;
        if (form) {
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({ SUB_TOTAL: subTotal.toFixed(2) });
            this.handleCalculateSubTotal();
        }
    }
    handleEditItem = async (record) => {
        const form = this.formRef.current;
        if (form) {
            form.setFieldsValue({
                ITEM: record.ITEM_ID,
                PRICE: record.PRICE,
                QUANTITY: record.QUANTITY,
                TOTAL: record.TOTAL,
            });

            await this.setState((prevState) => ({
                tableData: prevState.tableData.filter((item) => item !== record),
            }));

            // Calculate Sub Total and update the form
            const subTotal = this.state.tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
            this.state.itemSubTotal = subTotal;
            form.setFieldsValue({ SUB_TOTAL: subTotal.toFixed(2) });

            this.handleCalculateSubTotal();

            this.setState({
                currentItemName: record.ITEM_NAME,
                currentItemCode: record.ITEM_CODE,
                itemMaxQuantity: record.QUANTITY,
                showOverMaxQuantityWarning: false,
            });
        }
    }

    async fetchTransactionOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getTransactionForReference");
            //console.log("response", response);
            return response.data.result.map((transaction) => ({
                value: transaction.TRANSACTION_ID,
                label: transaction.CODE,
                ref: transaction.ITEM_CODE,
            }));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }



    render() {
        const inputStyle = {
            width: '100%',
            height: '30px',
        };

        let rememberedUser = Cookies.get('rememberedUser');
        let ROLE = null;

        if (rememberedUser) {
            rememberedUser = JSON.parse(rememberedUser);
            ROLE = rememberedUser.ROLE;
        }

        if(ROLE === 'USER_MILL'){
            return <Redirect to="/dashboard" />
        }

        const {   tableData,ReferenceOptions,type,TransactionOptions } = this.state;


        return (

            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                className="criclebox tablespace mb-24"
                                // title="Add New Return"
                            >
                                <Form
                                    layout="vertical"
                                    onFinish={this.handleSubmit}
                                    style={{ margin: '20px' }}
                                    ref={this.formRef}

                                >
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="TYPE"
                                                label="Return / Loss"
                                                initialValue={type}
                                                rules={[{ required: true, message: 'Please select Type' }]}
                                            >
                                                <Select placeholder="Select Return Type" showSearch disabled onChange={(value) => this.setState({ type: value })}>
                                                    <Option value="Returns">Returns</Option>
                                                    <Option value="Losses">Losses</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="DATE"
                                                label="Return Date"
                                                initialValue={moment(this.props.initialValues ? this.props.initialValues.DATE : new Date())}
                                                rules={[{ required: true, message: 'Please select Return Date' }]}
                                            >
                                                <DatePicker style={inputStyle} placeholder="Select Return Date" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            {/* Gem Type */}
                                            <Form.Item
                                                name="TRANSACTION_ID"
                                                label="Transaction Code"
                                                rules={[{ required: true, message: 'Please select Transaction Code' }]}
                                                initialValue={this.props.initialValues ? this.props.initialValues.TRANSACTION_ID : null}
                                            >
                                                <Select
                                                    placeholder="Select Transaction Code"
                                                    showSearch
                                                    disabled
                                                    onChange={this.handleTransactionChange}
                                                    filterOption={(input, option) =>
                                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                    }>
                                                    >
                                                    {TransactionOptions.map((option) => (
                                                        <Option key={option.value} value={option.value} title={option.label} >
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Divider />
                                    </Row>
                                    <Row gutter={[16, 16]} justify="left" align="top">

                                        <Col xs={24} sm={24} md={24} lg={9}>
                                            <Form.Item
                                                name="ITEM"
                                                label="Item"
                                                defaultValue={1}
                                            >
                                                <Select placeholder="Select Item" allowClear showSearch onSelect={this.handleReferenceChange}
                                                        filterOption={(input, option) =>
                                                            // (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                                            // (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                            (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                        }>
                                                    {ReferenceOptions.map((option) => (
                                                        <Option key={option.value} value={option.value} title={option.code}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={3}>
                                            <Form.Item
                                                name="PRICE"
                                                label={`Price Per ${this.state.Unit}`}
                                            >
                                                <InputNumber
                                                    style={inputStyle}
                                                    min={0}
                                                    step={0.01}
                                                    placeholder="Enter Price"
                                                    onChange={this.handleCalculateTotal}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="QUANTITY"
                                                label="Quantity"
                                            >
                                                <InputNumber
                                                    style={inputStyle}
                                                    min={0}
                                                    placeholder="Enter Quantity"
                                                    onChange={this.handleCalculateTotal}
                                                    onPressEnter={e => {
                                                        e.preventDefault(); // Prevent the default behavior (form submission)
                                                        this.handleAddItemToTable(); // Call your custom function
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={5}>
                                            <Form.Item
                                                name="TOTAL"
                                                label="Total (Rs)"
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Total" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={1}>
                                            <Form.Item
                                                label=" "
                                                name="ADD_ITEM_TO_TABLE"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={this.handleAddItemToTable}
                                                >
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {(this.state.showOverMaxQuantityWarning && type === 'Selling') ? <p style={{ color: 'red' }}>Quantity exceeds the available stock</p> : null}
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Table
                                            className="ant-border-space"
                                            columns={[
                                                {
                                                    title: 'Item Code',
                                                    dataIndex: 'ITEM_CODE',
                                                },
                                                {
                                                    title: 'Item Name',
                                                    dataIndex: 'ITEM_NAME',
                                                },
                                                {
                                                    title: 'Price Per Unit',
                                                    dataIndex: 'PRICE',
                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return 'Rs ' + record.PRICE.toFixed(2)
                                                    }
                                                },
                                                {
                                                    title: 'Quantity',
                                                    dataIndex: 'QUANTITY',
                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return record.QUANTITY;
                                                    }
                                                },
                                                {
                                                    title: 'Total (Rs)',
                                                    dataIndex: 'TOTAL',
                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return 'Rs ' + record.TOTAL.toFixed(2);
                                                    }
                                                },
                                                {
                                                    title: 'Action',
                                                    dataIndex: 'action',
                                                    align: 'center',
                                                    render: (_, record) => (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button type="default" onClick={() => this.handleEditItem(record)}>
                                                                <EditOutlined />
                                                            </Button>
                                                            <Divider type="vertical" />
                                                            <Button danger onClick={() => this.handleDeleteItem(record)}>
                                                                <DeleteOutlined />
                                                            </Button>
                                                        </div>
                                                    ),
                                                }

                                            ]}
                                            dataSource={tableData}
                                            rowKey={(record, index) => index}
                                            pagination={false}
                                            scroll={{ y: 240 }}
                                            bordered
                                            footer={() => {
                                                // Calculate and display the subtotal
                                                const subTotal = tableData.reduce((acc, item) => acc + Number(item.TOTAL), 0);
                                                return (
                                                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                        Sub Total (Rs): {subTotal.toFixed(2)}
                                                    </div>
                                                );
                                            }}
                                        />

                                    </Row>
                                    <Divider />
                                    <Row gutter={[16, 16]} justify="left" align="top">

                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="SUB_TOTAL"
                                                label={type === 'Returns' ? 'Return Amount (Rs)' : 'Loss Amount (Rs)'}
                                                rules={[{ required: true, message: 'Please enter Sub Total' }]}
                                                initialValue={this.props.initialValues ? this.props.initialValues.SUB_TOTAL : null}
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Sub Total"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={18}>
                                            <Form.Item
                                                name="COMMENTS"
                                                label="Comments"
                                                initialValue={this.props.initialValues ? this.props.initialValues.COMMENTS : null}
                                            >
                                                <Input.TextArea rows={2} placeholder="Enter comments" />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    {type === 'Returns' ? 'Update Return' : 'Update Loss'}
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
