// /* eslint-disable */
import React, { Component } from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Row,
    Select,
    Table,
} from 'antd';
import axios from 'axios';
import Cookies from "js-cookie";



const { Option } = Select;

const { RangePicker } = DatePicker;


class Reports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            selectedItems: null,
            referenceOptions: [],
            searchStartDate: null,
            searchEndDate: null,
            totalSellAmount: 0,
            totalBuyAmount: 0,
            totalReturnAmount: 0,
            totalLostAmount: 0,
            profit: 0,
        };


    }

    async componentDidMount() {
        const referenceOptions = await this.fetchReferenceOptions();
        this.setState({referenceOptions});
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsForReference');
            // console.log('response', response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.CODE + ' - ' + ref.NAME,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }


    handleGetRecords = async (values) => {
        const { searchStartDate, searchEndDate, selectedItems } = this.state;

        if (!searchStartDate || !searchEndDate) {
            message.error('Please select a date range');
            return;
        }

        this.setState({ loading: true });

        try {
            const response = await axios.post('https://iontheway.store/api/getMakeJuiceReport', {
                startDate: searchStartDate,
                endDate: searchEndDate,
                selectedItems: selectedItems,
            });

            if (response.data.success) {
                console.log('response123', response);

                this.setState({
                    tableData: response.data.result,
                    totalSellAmount: response.data.totalSellAmount,
                    totalBuyAmount: response.data.totalBuyAmount,
                    totalReturnAmount: response.data.totalReturnAmount,
                    totalLostAmount: response.data.totalLostAmount,
                    profit: response.data.profit,
                });
                console.log('tableData', this.state.tableData);
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    generateCSV = () => {
        const { tableData, totalSellAmount, totalBuyAmount, totalReturnAmount, totalLostAmount, profit } = this.state;

        // Prepare CSV header
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Item,Total Sold Quantity,Total Sold Amount (Rs.),Total Bought Quantity,Total Bought Amount (Rs.),Total Wasted Quantity,Total Wasted Amount (Rs.),Total Return Quantity,Total Return Amount (Rs.)\n";

        // Add rows of data
        tableData.forEach((row) => {
            const rowData = [
                row.ITEM_NAME,
                row.SELLING_QUANTITY,
                row.SELLING_AMOUNT.toFixed(2),
                row.BUYING_QUANTITY,
                row.BUYING_AMOUNT.toFixed(2),
                row.LOST_QUANTITY > 0 ? row.LOST_QUANTITY : 0, // Wasted Quantity
                row.LOST_AMOUNT > 0 ? row.LOST_AMOUNT.toFixed(2) : 0, // Wasted Amount
                row.RETURN_QUANTITY,
                row.RETURN_AMOUNT.toFixed(2)
            ];
            csvContent += rowData.join(",") + "\n";
        });

        // Add total row at the bottom
        csvContent += [
            "",
            "",
            totalSellAmount.toFixed(2), // Total Sold Amount
            "",
            totalBuyAmount.toFixed(2), // Total Bought Amount
            "", // Placeholder for Total Wasted Quantity
            totalLostAmount.toFixed(2), // Total Wasted Amount
            "",
            totalReturnAmount.toFixed(2) // Total Return Amount
        ].join(",") + "\n";

        // Add profit row
        csvContent += [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Profit (Rs.): " + profit.toFixed(2) // Profit
        ].join(",") + "\n";

        // Encode URI and trigger download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    }





    render() {

        const rememberedUser = Cookies.get('rememberedUser');
        const ROLE = rememberedUser ? JSON.parse(rememberedUser).ROLE : null;

        if (!ROLE || ROLE !== 'ADMIN') {
            return (
                <div>
                    <h1 style={{ textAlign: 'center', marginTop: '50px' }}>You are not authorized to view this page</h1>
                </div>
            );
        }
        return (
            <>
                <div>
                    <Form
                        ref={this.formRef}
                        initialValues={{
                            selectedItems: null,
                            searchStatus: null,
                            searchDateRange: null,
                        }}
                        onFinish={this.handleGetRecords}
                    >
                        <Row gutter={[16, 16]} justify="left" align="top">
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="searchDateRange"
                                           rules={[{ required: true, message: 'Please select a date range' }]}>
                                    <RangePicker
                                        style={{ width: '100%' }}
                                        onChange={(dates) => {
                                            this.setState({
                                                searchStartDate: dates && dates.length === 2 ? dates[0].format('YYYY-MM-DD') : null,
                                                searchEndDate: dates && dates.length === 2 ? dates[1].format('YYYY-MM-DD') : null,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item name="itemReference">
                                    <Select placeholder="Select Items / to select all leave empty"
                                            style={{ width: '100%' }}
                                            mode={'multiple'}
                                            allowClear
                                            onChange={(value) => {this.setState({selectedItems: value})} }
                                            filterOption={(input, option) =>
                                                // (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                                // (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                            }>
                                        {this.state.referenceOptions.map((option) => (
                                            <Option key={option.value} value={option.value} title={option.code}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={6}>
                                <Form.Item>
                                    <Button type="default" htmlType="submit" style={{ marginRight: '8px' }}>
                                        Get Records
                                    </Button>
                                    {this.state.tableData.length > 0 && (
                                        <Button type="default" onClick={this.generateCSV} style={{ marginRight: '8px', color: 'green' }}>
                                            Generate CSV
                                        </Button>

                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </div>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Reports"
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="ITEM_ID"
                                        columns={[
                                            {
                                                title: 'Item',
                                                dataIndex: 'ITEM_NAME',
                                            },
                                            {
                                                title: 'Total Sold Quantity',
                                                dataIndex: 'SELLING_QUANTITY',
                                                align: 'right',
                                            },
                                            {
                                                title: 'Total Sold Amount (Rs.)',
                                                dataIndex: 'SELLING_AMOUNT',
                                                align: 'right',
                                                render: (text, record) => {
                                                    return record.SELLING_AMOUNT.toFixed(2);
                                                }

                                            },
                                            {
                                                title: 'Total Bought Quantity',
                                                dataIndex: 'BUYING_QUANTITY',
                                                align: 'right',
                                                render: (text, record) => {
                                                    // return Bought BUYING_QUANTITY and Wasted LOST_QUANTITY
                                                    return (
                                                        <span>
                {record.BUYING_QUANTITY}
                                                            {record.LOST_QUANTITY > 0 && (
                                                                <span style={{ color: 'red' }}>
                        {' -> ' + record.LOST_QUANTITY + ' (Wasted)'}
                    </span>
                                                            )}
            </span>
                                                    );
                                                }
                                            },
                                            {
                                                title: 'Total Bought Amount (Rs.)',
                                                dataIndex: 'BUYING_AMOUNT',
                                                align: 'right',
                                                render: (text, record) => {
                                                    return (
                                                        <span>
                {record.BUYING_AMOUNT.toFixed(2)}
                                                            {record.LOST_AMOUNT > 0 && (
                                                                <span style={{ color: 'red' }}>
                        {' -> ' + record.LOST_AMOUNT.toFixed(2) + ' (Wasted)'}
                    </span>
                                                            )}
            </span>
                                                    );
                                                }
                                            },

                                            {
                                                title: 'Total Return Quantity',
                                                dataIndex: 'RETURN_QUANTITY',
                                                align: 'right',
                                                render: (text, record) => {
                                                    return record.RETURN_QUANTITY;
                                                }
                                            },
                                            {
                                                title: 'Total Return Amount (Rs.)',
                                                dataIndex: 'RETURN_AMOUNT',
                                                align: 'right',
                                                render: (text, record) => {
                                                    return record.RETURN_AMOUNT.toFixed(2);
                                                }
                                            },
                                        ]}
                                        dataSource={this.state.tableData}
                                        // pagination={true}
                                        footer={() => (
                                            <div>
                                                <Divider />
                                                <Row>
                                                    <Col span={6} style={{ textAlign: 'right' }}>
                                                        <h3>Total Sell Amount: Rs. {this.state.totalSellAmount.toFixed(2)}</h3>
                                                    </Col>
                                                    <Col span={6} style={{ textAlign: 'right' }}>
                                                        <h3>
                                                            Total Buy Amount: Rs. {this.state.totalBuyAmount.toFixed(2)}
                                                            {this.state.totalLostAmount > 0 && (
                                                                <span style={{ color: 'red' }}>
                {' -> ' + this.state.totalLostAmount.toFixed(2) + ' (Wasted)'}
            </span>
                                                            )}
                                                        </h3>
                                                    </Col>

                                                    <Col span={6} style={{ textAlign: 'right' }}>
                                                        <h3>Total Returns Amount: Rs. {this.state.totalReturnAmount.toFixed(2)}</h3>
                                                    </Col>
                                                    <Col span={6} style={{ textAlign: 'right' }}>
                                                        <h3>Profit: Rs. {this.state.profit.toFixed(2)}</h3>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Reports;
