// /* eslint-disable */
import React, {Component} from "react";
import {
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Select,
    Button,
    message,
    DatePicker,
    Divider, Input
} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import moment from 'moment';
import {RightOutlined} from "@ant-design/icons";

const { Option } = Select;

export default class PaymentUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {

            customerOptions: [],
            // ReferenceOptions: [],
            TransactionOptions: [],

        };
    }
    formRef = React.createRef();
    handleCalculateDueAmount = () => {
        const form = this.formRef.current;
        if (form) {
            const currentDueAmount = form.getFieldValue('DUE_AMOUNT') || 0;
            const paymentAmount = form.getFieldValue('PAYMENT_AMOUNT') || 0;

            const newDueAmount = currentDueAmount - paymentAmount;

            form.setFieldsValue({ DUE_AMOUNT_AFTER_PAYMENT: newDueAmount });
        }
    };


    async componentDidMount() {
        // Fetch customer options when the component mounts
        const customerOptions = await this.fetchCustomerOptions();
        this.setState({ customerOptions });
        // const ReferenceOptions = await this.fetchReferenceOptions();
        // this.setState({ ReferenceOptions });
        const TransactionOptions = await this.fetchTransactionOptions();
        this.setState({ TransactionOptions });
    }

    handleTransactionTypeChange = (value) => {
        const form = this.formRef.current;
        this.setState({ type: value });
        if (value === 'Buying') {
            form.setFieldsValue({ STATUS: 'Working' });
        }
        else if (value === 'Selling') {
            form.setFieldsValue({ STATUS: 'Sold' });
        }
    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getAllCustomers");
            //console.log("response", response);


            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    // async fetchReferenceOptions() {
    //   try {
    //     const response = await axios.post("https://iontheway.store/api/getItemsForReference");
    //     //console.log("response", response);
    //     return response.data.result.map((ref) => ({
    //       value: ref.ITEM_ID,
    //         label: ref.IS_IN_INVENTORY === 0 ? ref.CODE + " - (Not in Inventory)" : ref.CODE
    //     }));
    //   } catch (error) {
    //     console.error("Error fetching reference options:", error);
    //     return [];
    //   }
    // }

    async fetchTransactionOptions() {
        try {
            const response = await axios.post("https://iontheway.store/api/getTransactionForReference");
            //console.log("response", response);
            return response.data.result.map((transaction) => ({
                value: transaction.TRANSACTION_ID,
                label: transaction.CODE,
                ref: transaction.ITEM_CODE,
            }));
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }


    handleTransactionChange = async (value) => {
        const form = this.formRef.current;
        try {
            const response = await axios.post('https://iontheway.store/api/getTransactionDetails', {
                TRANSACTION_ID: value,
            });
            if (response.data.success) {
                //console.log("response", response);
                form.setFieldsValue({ METHOD: response.data.result[0].METHOD });
                form.setFieldsValue({ CUSTOMER: response.data.result[0].CUSTOMER });
                form.setFieldsValue({ SUB_TOTAL: response.data.result[0].SUB_TOTAL });
                form.setFieldsValue({ AMOUNT_SETTLED: response.data.result[0].AMOUNT_SETTLED });
                form.setFieldsValue({ DUE_AMOUNT: response.data.result[0].DUE_AMOUNT });
                form.resetFields(['DUE_AMOUNT_AFTER_PAYMENT']);

                this.handleTransactionTypeChange(response.data.result[0].TYPE);
            } else {
                message.error('Failed to fetch Item Details');
            }
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    }



    handleSubmit = async (values) => {
        try {

            const mainData = {
                ...values,
                TRANSACTION_ID: this.props.initialValues.TRANSACTION_ID,
            };

            const response = await axios.post('https://iontheway.store/api/updatePayment', mainData);
            if (response.data.success) {
                message.success('Payment updated successfully');
                this.props.onUpdate();
                this.props.onCancel();
            } else {
                message.error('Failed to update Payment');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to update Payment');
        }
    };



    render() {
        const {   customerOptions,ReferenceOptions,TransactionOptions,type } = this.state;

        const inputStyle = {
            width: '100%',
            pointerEvents: "none", // Disable pointer events to prevent interaction
            background: "#ffffff", // Set a background color to indicate it's style={inputStyle}
            color: "#000000"
        };


        return (

            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                className="criclebox tablespace mb-24"
                            >
                                <Form
                                    layout="vertical"
                                    onFinish={this.handleSubmit}
                                    style={{ margin: '20px' }}
                                    ref={this.formRef}
                                >
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            {/* Gem Type */}
                                            <Form.Item
                                                name="TRANSACTION"
                                                label="Transaction Code"
                                                rules={[{ required: true, message: 'Please select Transaction Code' }]}
                                                initialValue={this.props.initialValues.REFERENCE_TRANSACTION}
                                            >
                                                <Select
                                                    placeholder="Select Transaction Code"
                                                    showSearch
                                                    disabled
                                                    onChange={this.handleTransactionChange}
                                                    filterOption={(input, option) =>
                                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                    }>
                                                    >
                                                    {TransactionOptions.map((option) => (
                                                        <Option key={option.value} value={option.value} title={option.label} >
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="METHOD"
                                                label="Method"
                                                initialValue={this.props.initialValues.METHOD}
                                                rules={[{ required: true, message: 'Please select Transaction Method' }]}
                                            >

                                                <Select placeholder="Select Payment Method" allowClear showSearch>
                                                    <Option value="Cash">Cash</Option>
                                                    <Option value="Cheque">Cheque</Option>
                                                    <Option value="Bank Transfer">Bank Transfer</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            {/* Date */}
                                            <Form.Item
                                                name="DATE"
                                                label="Date"
                                                rules={[{ required: true, message: 'Please select Date' }]}  // Set the default date to today
                                                initialValue={moment(this.props.initialValues.DATE)}
                                            >
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="CUSTOMER"
                                                label="Customer"
                                                initialValue={this.props.initialValues.CUSTOMER}
                                            >
                                                <Select placeholder="Select Customer" allowClear showSearch style={inputStyle}
                                                        filterOption={(input, option) =>
                                                            (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                                            (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                                        }>
                                                    {customerOptions.map((option) => (
                                                        <Option key={option.value} value={option.value} title={option.label}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider />

                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="SUB_TOTAL"
                                                label="Amount"
                                                rules={[{ required: true, message: 'Please enter Amount' }]}
                                                initialValue={this.props.initialValues.SUB_TOTAL}
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Amount"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="AMOUNT_SETTLED"
                                                label="Amount Settled"
                                                rules={[{ required: true, message: 'Please enter Amount Settled' }]}
                                                initialValue={(
                                                    parseFloat(this.props.initialValues.AMOUNT_SETTLED) -
                                                    parseFloat(this.props.initialValues.PAYMENT_AMOUNT)
                                                ).toFixed(2)}
                                            >
                                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Amount Settled"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="DUE_AMOUNT"
                                                label="Due Amount"
                                                rules={[{ required: true, message: 'Please enter Due Amount' }]}
                                                initialValue={(
                                                    parseFloat(this.props.initialValues.DUE_AMOUNT) +
                                                    parseFloat(this.props.initialValues.PAYMENT_AMOUNT)
                                                ).toFixed(2)}
                                            >
                                                <InputNumber style={inputStyle} step={0.01} placeholder="Enter Due Amount"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={6}>
                                            <Form.Item
                                                name="PAYMENT_AMOUNT"
                                                label="Payment Amount"
                                                rules={[{ required: true, message: 'Please enter Payment Amount' }]}
                                                initialValue={this.props.initialValues.PAYMENT_AMOUNT}
                                            >
                                                <InputNumber step={0.01} placeholder="Enter Payment Amount" style={{ width : '100%' }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={1}>
                                            <Form.Item
                                                label=" "
                                                name="CALCULATE_DUE_AMOUNT"
                                            >
                                                <Button
                                                    type="default"
                                                    icon={<RightOutlined />}
                                                    onClick={this.handleCalculateDueAmount}
                                                >
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={5}>
                                            <Form.Item
                                                name="DUE_AMOUNT_AFTER_PAYMENT"
                                                label="Due Amount After Payment"
                                                rules={[{ required: true, message: 'Please enter Due Amount After Payment' }]}
                                                initialValue={this.props.initialValues.DUE_AMOUNT}
                                            >
                                                <InputNumber step={0.01} placeholder="Enter Due Amount After Payment" style={{ width : '100%' }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item
                                                name="COMMENTS"
                                                label="Comments"
                                                initialValue={this.props.initialValues.COMMENTS}
                                            >
                                                <Input.TextArea rows={2} placeholder="Enter comments" />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Update Payment
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            {/*

                */}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
